* {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu;
  color: #454545;
  font-size: 12px;
}

body {
  background-image: url("/background.jpg");
  background-size: cover;
}
