@media (min-width: 480px) {
  .xs-block {
    display: block;
  }

  .xs-inline-block {
    display: inline-block;
  }

  .xs-hidden {
    display: none;
  }
}

@media (min-width: 768px) {
  .md-hidden {
    display: none !important;
  }
}

.topnav {
  background: #efefef;
  border-bottom: 1px solid #d6d6d6;
}

.window {
  border: 1px solid #d6d6d6;
  border-radius: 6px;
  height: 80vh;
}

.window-minus-taskbar {
  padding-top: 32px;
  margin-top: -32px;
}

.taskbar {
  background: rgb(206, 206, 206);
  background: linear-gradient(
    0deg,
    rgba(206, 206, 206, 1) 0%,
    rgba(245, 245, 245, 1) 100%
  );
  border-bottom: 1px solid #d6d6d6;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  height: 32px;
}

.traffic-light {
  border-radius: 50%;
  width: 13px;
  height: 13px;
}

.traffic-light--red {
  background: #fc625d;
}

.traffic-light--yellow {
  background: #fdbd41;
}

.traffic-light--green {
  background: #34c84a;
}

.page-colour {
  background: #fafaf7;
}

.sidebar-selected {
  background: #dcdcdb;
}

.directory-selected {
  background: #d2d2d2;
}

.directory-selected:focus {
  outline: 0;
  background: #4d96f5;
  color: white;
}

.directory-focus {
  background: #4d96f5;
  color: white;
}

.directory-rename {
  background: #4d96f5;
  width: 100%;
}

.sidebar {
  border-right: 1px solid #d6d6d6;
  width: 200px;
}

.sidebar-post {
  border-bottom: 1px solid #d6d6d6;
}

.sidebar-post:focus {
  background: #f8da72;
  outline: 0;
}

.directories {
  background: #ededed;
  width: 170px;
}

.folder-label {
  outline: none;
}

.folder-label .delete-folder {
  display: none;
}

.directory-selected + .delete-folder {
  background: #d2d2d2;
}

.directory-selected:focus + .delete-folder {
  background: #4d96f5;
}

.folder-label:hover .delete-folder {
  display: inline;
}

.emoji {
  padding-left: 5px;
  font-size: 16px;
}

.pinned-note + :not(.pinned-note) {
  border-top: 2px solid #bbb;
  margin-top: -1px;
}

.pin-note-button {
  display: none;
  bottom: 8px;
  right: 3px;
}

.note-li:hover .pin-note-button {
  display: block;
}
